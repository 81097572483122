import React, { useState, useEffect } from 'react';
//import { FormSelect } from 'components/Form/Form';
import Spinner from 'components/0components/Spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CalendrierProvinceLayout from './CalendrierProvinceLayout';
import { yearsListBetween } from 'lib/utils';
import { ClubApi } from 'api/ClubApi';
import { MarchesApi } from 'api/MarchesApi';
import { Auth, FormSelect } from 'components/Form/Form';

export default ({ groupid  }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)}>
        <button className="btn btn-primary" >
            Calendrier <i className="fas fa-fw fa-file-pdf ml-1" />
        </button>
        {isVisible && <MyModal groupid={groupid} isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ groupid: id, isVisible, setIsVisible }) => {

    const currentYear = new Date().getFullYear();
    const beginYear = 1979; //pour le modal
    const endYear = currentYear + 2; //pour le modal
    const [annee, setAnnee] = useState(currentYear); //année de recherche
    

    const [province, setProvince] = useState();
    const [marches, setMarches] = useState();

    const [respCalProv, setRespCalProv] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [canPdfBeGenerated, setCanPdfBeGenerated] = useState(false);

    useEffect(_ => {
        setRespCalProv()
        fetch(`api/v1/orga/${id}/prov2/Calendrier`, Auth.requestOptions("GET"))
            .then(res => res.json())
            .then(res => setRespCalProv(res));
    }, [id]);

    useEffect(_ => {
        setMarches();
        setProvince();
        setIsLoading(true);
        setCanPdfBeGenerated(false);

        Promise.all([
            MarchesApi.getFromProvinceV3(id, annee),
            ClubApi.get(id)
        ])
        .then(res => {
            setMarches(res[0]);
            setProvince(res[1]);
        })
        .finally(_ => {
            setIsLoading(false);
            setCanPdfBeGenerated(true);
        })

    }, [id, annee]);

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>Génération Calendrier</ModalHeader>
        <ModalBody>
            <FormSelect label="Année" value={annee} handleChange={val => setAnnee(val)}>
                {yearsListBetween(beginYear, endYear).map((e, i) => <option value={e} key={i}>{e}</option>)}
            </FormSelect>

            {canPdfBeGenerated &&
                <CalendrierProvinceLayout
                    data={{
                        marches: marches,
                        province: province,
                        annee: annee,
                        responsableProvince: respCalProv,
                    }}
                    fileName={`Calendrier_${province?.name}_${annee}`}
                />}

            {isLoading && <>
                <p>Chargement des données...</p>
                <Spinner />
            </>}

        </ModalBody>
    </Modal>);
}
